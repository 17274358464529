import Gem from "./Gem";
import { Gem as GemModel } from "./useGems";

import GemUrl from "./assets/spta/nonna-head.png";

import "./Gems.css";

interface GemsProps {
  gems: GemModel[];
  gemCounter: number;
  onTakeGem: (gemId: number) => void;
  dinoRef: React.RefObject<HTMLDivElement>;
  timeToDisplay: string;
  onStopClicked: () => void;
}

const Gems = ({
  gems,
  gemCounter,
  onTakeGem,
  dinoRef,
  timeToDisplay,
  onStopClicked,
}: GemsProps) => {
  return (
    <>
      <div className="counter-container">
        <div className="gem-counter">
          <img src={GemUrl} alt="" />
          <span>{gemCounter}</span>
        </div>
        <div className="counter">
          <span>{timeToDisplay}</span>
        </div>
      </div>
      <button
        style={{
          textAlign: "center",
          position: "fixed",
          top: "135px",
          left: "50%",
          height: "40px",
          transform: "translate(-50%, 0)",
          padding: "10px",
          backgroundColor: "hsla(0deg 0% 100%)",
          border: "2px solid white",
          borderRadius: "10px",
          zIndex: 99999999999,
        }}
        onClick={onStopClicked}
        className="button"
        id="stop-button"
      >
        STOP
      </button>

      <div className="gems-wrapper">
        {gems.map((gem) => (
          <Gem
            key={gem.id}
            id={gem.id}
            bottom={gem.bottom}
            left={gem.left}
            imageUrl={gem.imageUrl}
            dinoRef={dinoRef}
            onTakeGem={onTakeGem}
            isTaken={gem.isTaken}
            artist={gem.artist}
          />
        ))}
      </div>
    </>
  );
};

export default Gems;
