import "./Welcome.css";

interface WelcomeProps {
  onPlayPressed: () => void;
}

export const Welcome = ({ onPlayPressed }: WelcomeProps) => {
  return (
    <div className="wrapper">
      <div className="container">
        <div className="inner">
          <h1>Game rules</h1>
          <p>
            Aiuta Stefano a portare la pizza alla sua mamma!
            <br />
            Sbrigatevi, il forno è caldo!
            <br /> Forza !!
          </p>
          <button onClick={onPlayPressed} className="button">
            Play
          </button>
        </div>
      </div>
    </div>
  );
};
